body{
  color: #405c43;
  background-color: darkgray;
  &.modal-shown{
    overflow-y: hidden;
  }
}

/* div, button{
  border-radius: 5px;
} */

input, select{
  border: 1px solid #b6cbb8;
  color: #405c43;
  /* color: rgba(0, 0, 0, 0.5); */
  font-size: 12px;
  padding: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  /* min-height: 10px; */
  /* color: rgba(0, 0, 0, 0.5); */
}

div, input, button, select, label{
  border-radius: 10px;
}

input:focus-visible{
  outline: 1px solid #57b2c7;
}

input::placeholder{
  color: rgba(0, 0, 0, 0.5);
}

button, input[type=submit]{
  border: 0;
  font-size: 20px;
  padding: 10px 18px;
  background: #a3729d;
  color: white;
  cursor: pointer;
  min-width: 100px;
}

button:focus-visible{
  outline: 1px solid #57b2c7;
}
